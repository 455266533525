import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

const TeamCard = ({ name, description, image }) => {
    const [imgLoaded, setImgLoaded] = useState(false);
  const { ref, inView } = useInView({ triggerOnce: true });
return (
    <Card className='cardPlayer' sx={{ maxWidth: 320 }}>
      <CardActionArea>
      <div ref={ref} style={{ position: 'relative' }}>
          {!imgLoaded && <Skeleton variant="rectangular" width="100%" height={140} />}
          {inView && (
            <CardMedia
              component="img"
              image={image}
              alt={`Image of ${name}`}
              onLoad={() => setImgLoaded(true)}
              style={{ display: imgLoaded ? 'block' : 'none' }}
            />
          )}
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default TeamCard;
