import React, { useState } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Logo from '../images/logo-yellow.png';
import MobileLogo from '../images/logo.png';
import { Link } from 'react-router-dom';

function Navbar() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    function GoToInagramGroup() {
        window.open('https://www.instagram.com/ename_city/', '_blank');
    }

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button component="a" href="/">
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button component="a" href="/team">
                    <ListItemText primary="Team" />
                </ListItem>
                <ListItem button component="a" href="/matches">
                    <ListItemText primary="Matches" />
                </ListItem>
                <ListItem button onClick={GoToInagramGroup}>
                    <InstagramIcon style={{ marginRight: 8 }} />
                    <ListItemText primary="Follow Us" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className='nav'>
            <Link to='/' className='nav-title'>
                <img className='nav-logo color-secondary desktop-logo' src={Logo} alt='Ename City Logo' />
                <img className='nav-logo color-secondary mobile-logo' src={MobileLogo} alt='Ename City Mobile Logo' />
                Ename City
            </Link>
            <div className='nav-links desktop'>
                <Link to='/'>Home</Link>
                <Link to='/team'>Team</Link>
                <Link to='/matches'>Matches</Link>
                <Button onClick={GoToInagramGroup} disableElevation variant="contained" startIcon={<InstagramIcon />}>
                    Follow Us
                </Button>
            </div>
            <div className='nav-links mobile'>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                    {list()}
                </Drawer>
            </div>
        </div>
    );
}

export default Navbar;
