
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData(
    Match,
    Event,
    Date,
    Location,
    Result
  ) {
    return { Match, Event, Date, Location, Result };
  }
  
  const rows = [
    createData('Ename City VS International Men', 'Student Street Soccer 2024', '17/04/2024 13:30', 'Sint Pietersplein @veld 2', '5 - 2'),
    createData('Ename City VS SKOL', 'Student Street Soccer 2024', '24/04/2024 14:30', 'Sint Pietersplein @veld 5', '3 - 3'),
    createData('Ename City VS Victorius Secret FCG', 'Student Street Soccer 2024', '01/05/2024 13:30', 'Sint Pietersplein @veld 4', '1 - 12' ),
  ];

const  Matches = () => {
  return (
    <Container  className='top-50 teamContainer'  maxWidth="lg">
            <Typography className='bold color-primary' variant="h2">
                    Matches
            </Typography>
            <Typography  variant="body1" className='top-25' sx={{width:'80%'}} gutterBottom>
                This is the schedule of Ename City. Everyone is welcome to come and support us during our matches.  
            </Typography>
            <Divider className='top-25 bottom-25'/>
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Match</StyledTableCell>
            <StyledTableCell align="right">Event</StyledTableCell>
            <StyledTableCell align="right">Date</StyledTableCell>
            <StyledTableCell align="right">Location</StyledTableCell>
            <StyledTableCell align="right">Result</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.Match}>
              <StyledTableCell component="th" scope="row">
                {row.Match}
              </StyledTableCell>
              <StyledTableCell align="right">{row.Event}</StyledTableCell>
              <StyledTableCell align="right">{row.Date}</StyledTableCell>
              <StyledTableCell align="right">{row.Location}</StyledTableCell>
                <StyledTableCell align="right">{row.Result}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
}

export default Matches;