
import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import BelgaPack from '../images/Sponsers/belgapack.png';
import GunPragma from '../images/Sponsers/Gun-Pragma.png';
import KapsalonCindy from '../images/Sponsers/kapsaloncindy.png';
import TeamShirts from '../images/Sponsers/teamshirts.png';



const Sponsors = () => {
    const itemData = [
        {
            img: BelgaPack,
                title: 'BelgaPack',
            },
            {
            img: GunPragma,
                title: 'Gun Pragma',
            },
            {
                img: KapsalonCindy,
                    title: 'Kapsalon Cindy',
            },
            {
            img: TeamShirts,
                title: 'Team Shirts',
            },
    ];
    return (
<div className='bg-dark'>


    <Container  className='top-50 container '  maxWidth="lg">
        <Typography className='' variant="h4">
                Powered By
        </Typography>
        <Typography  variant="subtitle2" className='color-primary' gutterBottom>
            Our Sponsors
        </Typography>
        <Typography  variant="body1" sx={{width:'50%'}} gutterBottom>
            We are proud to be supported by these sponsors. They help us to achieve our goals and make sure we can play football in the best conditions
        </Typography>
        <Box className='top-25'  sx={{ width: '100%', height:'20vh', overflowY: 'hidden', overflowX:'hidden' }}>
            <ImageList className='next' variant="masonry" cols={4} gap={5}>
            {itemData.map((item) => (
            <ImageListItem key={item.img}>
                <img className='sponsor-image'
                srcSet={`${item.img}`}
                src={`${item.img}`}
                alt={item.title}
                loading="lazy"
                />
            </ImageListItem>
            ))}
        </ImageList>
        </Box>
        

    </Container>
</div>
        );
}

export default Sponsors;

