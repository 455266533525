import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Homepage from './components/Homepage';
import NotFound from './components/NotFound';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Team from './components/Team';
import Matches from './components/Matches';
import Divider from '@mui/material/Divider';




function App() {
  return (
    <>
    <Navbar />
    <Divider  sx={{
          width: '100%',
          margin: 'auto',      
          marginBottom: '0',
          marginTop: '0',
        }}/>
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/home" element={<Homepage />} />
      <Route path='/team' element={<Team />} />
      <Route path='/matches' element={<Matches />} />
    </Routes>
    <Footer />
    </>
   
  );
}

export default App;
