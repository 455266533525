import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Coach from '../images/Team/coach.gif';
import semw from '../images/Team/semw.gif';
import daans from '../images/Team/daans.gif';
import jarnovd from '../images/Team/jarnovd.gif';
import milanvc from '../images/Team/milanvc.gif';
import tristans from '../images/Team/tristans.gif';
import rubenve from '../images/Team/rubenve.gif';
import loicb from '../images/Team/loicb.gif';
import zionb from '../images/Team/zionb.gif';


import TeamCard from './TeamCard'; 
import Divider from '@mui/material/Divider';



const Team = () => {
  return (
    <Container  className='top-50 teamContainer'  maxWidth="lg">
         <Typography className='bold color-primary' variant="h2">
                The Team
        </Typography>
        <Typography  variant="body1" className='top-25' sx={{width:'80%'}} gutterBottom>
            This is the team of Ename City. We are a group of students from Ename who are passionate about football and Ename Blond. We participate in different football tournaments in Ghent and are always looking for new challenges. We are proud to present our team members to you. 
        </Typography>
        <Divider className='top-25'/>

    <div className='top-50 grid' >
    <TeamCard 
            name="Milan Van den Eede" 
            description="Milan is the coach of the team and is responsible for the training sessions and the tactics of the team." 
            image={Coach} 
          />
            <TeamCard 
                name="Sem Waeghe" 
                description="Sem is the captain of the team playing number 7. He leads the team and is responsible for the communication with the other teams." 
                image={semw}
            />
            <TeamCard 
                name="Daan Sardeur" 
                description="Daan takes his skills from his local football team and translates them to Ename City."
                image={daans}
            />
               <TeamCard 
                name="Ruben Van Eetvelde" 
                description="Also know as Nummer 4! He is a midfielder and is responsible for the teambulding." 
                image={rubenve}
            />
            <TeamCard 
                name="Loic Baudelet" 
                description="Loic is the striker of the team. He is responsible for the goals and the assists."
                image={loicb}
            />
            <TeamCard 
                name="Zion Botteldoorn" 
                description="Zion is the striker of the team and is responsible for the goals and the assists."
                image={zionb}
            />
            <TeamCard 
                name="Jarno Vandamme" 
                description="Defender" 
                image={jarnovd}
            />
            <TeamCard 
                name="Milan Van Cauwenberghe" 
                description="Midfielder" 
                image={milanvc}
            />
            <TeamCard 
                name="Tristan Schelstraete" 
                description="Midfielder" 
                image={tristans}
            />
    </div>
    </Container>
  );
}

export default Team;