
import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Nr7Image from '../images/EnameCityNr7.jpg';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ActionImage from '../images/EnameCityAction.jpg';
import Nr69Image from '../images/EnameCityNr69.jpg';
import RainImage from '../images/EnameCityRain.jpg';
import GroupImage from '../images/EnameCityGroup.jpg';
import Button from '@mui/material/Button';
import InstagramIcon from '@mui/icons-material/Instagram';



function Header() {

    const itemData = [
        {
          img: Nr7Image,
            title: 'Group Image Ename City',
        },
        {
          img: ActionImage,
            title: 'Action Image Ename City',
        },
        {
            img: GroupImage,
                title: 'Group Image Ename City',
        },
        {
          img: Nr69Image,
            title: 'Nr 69 Ename City',
        },
        {
          img: RainImage,
            title: 'Rain Image Ename City',
        },
    ];
    return (
<Container  className='top-50 next'  maxWidth="lg">
    <Box sx={{ width: '50%' }}>
        <Typography className='bold color-primary' variant="h2">
               Ename City
        </Typography>
        <Typography  variant="h2" className='HeaderSubTitle' gutterBottom>
            Student Football Club
        </Typography>
        <Typography className='color-primary' variant="subtitle2">
           A passionated student group from Ename, Belgium
        </Typography>
        <Typography variant='body1' gutterBottom>
            Ename City is een groep studenten uit Ename die dit jaar meedoen aan verschillende voetbaltoernooien in Gent en zijn grote fan van Ename Blond. Alle informatie over ons vind je hier en op onze socials terug
            <br/>
            <br/>
            Wil je ons volgen? Check dan zeker onze Instagram pagina uit! We posten regelmatig updates over onze wedstrijden en activiteiten
        </Typography>
        <div className=' top-25'>
        <Button className='right-5' variant="contained" disableElevation href='/team'  >
            Our Team
        </Button>
        <Button startIcon={<InstagramIcon />}  variant="outlined" disableElevation href='https://www.instagram.com/ename_city/' target='_blank' className='color-primary'>
            Instagram
        </Button>
        </div>
    </Box>
  

    <Box sx={{ width: '35%', height:'80vh', overflowY: 'hidden' }}>
              <ImageList variant="masonry" cols={2} gap={5}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
    

</Container>
        );
}

export default Header;

