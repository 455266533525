import React from 'react';
import Header from './Header';
import Sponsors from './Sponsors';


const Homepage = () => {
    return (
        <>
        <Header/>
        <Sponsors/>
      </>
    )
}

export default Homepage;