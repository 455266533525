import { useLocation, Link } from 'react-router-dom';
import '../App.css'


const NotFound = () => {
const { pathname } = useLocation();
  return (
    <div className="nietgevonden">
      <div>
        <h1>404</h1>
        <h2>Page not found</h2>
        <p>
            There is no page at this url: {pathname}
          , try something else
        </p>
       
      </div>
      <Link className="btnnietgevonden" to="/">GO BACK</Link>
    </div>
  );
}

export default NotFound;
